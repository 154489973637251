import useAuth from "@/modules/auth/composables/useAuth";
import {Ref, ref} from "vue";
import {v4 as uuid} from "uuid";
import {ItemCategoryForm} from "@/modules/inventory_configuration/interfaces";
import store from "@/store";

const {companyId} = useAuth()
const defaultValues = (): ItemCategoryForm => {
    return {
        id: uuid(),
        name: "",
        description: "",
        status: "active",
        companyId: companyId.value
    }
}
const itemCategoryForm: Ref<ItemCategoryForm> = ref(defaultValues())

const useItemCategory = () => {
    const catalogs = {
        status: [
            {id: 'active', title: 'Activo'},
            {id: 'inactive', title: 'Inactivo'},
        ]
    };

    const clearForm = () => itemCategoryForm.value = defaultValues()

    const createItemCategory = async (itemCategoryForm: ItemCategoryForm) => {
        return await store.dispatch('inventory_configuration/createItemCategory', itemCategoryForm)
    }

    const updateItemCategory = async (itemCategoryForm: ItemCategoryForm) => {
        return await store.dispatch('inventory_configuration/updateItemCategory', itemCategoryForm)
    }

    const getItemCategory = async (id: string): Promise<ItemCategoryForm> => {
        const {data} = await store.dispatch('inventory_configuration/getItemCategory', id)

        return data;
    }

    return {
        itemCategoryForm,
        catalogs,
        clearForm,
        createItemCategory,
        updateItemCategory,
        getItemCategory
    }
}

export default useItemCategory;
