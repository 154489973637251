
import {onMounted, ref, watch} from "vue";
import useItemCategory from "@/modules/inventory_configuration/composables/useItemCategory";
import usePanel from "@/composables/usePanel";

const {itemCategoryForm} = useItemCategory();

export default {
    props: ['catalogs'],
    setup() {
        const {showPanel, togglePanel} = usePanel()

        const name = ref('')
        const description = ref('')
        const status = ref('')

        onMounted(() => {
            name.value = itemCategoryForm.value.name
            description.value = itemCategoryForm.value.description
            status.value = itemCategoryForm.value.status
        })

        watch(name, val => itemCategoryForm.value.name = val)
        watch(description, val => itemCategoryForm.value.description = val)
        watch(status, val => itemCategoryForm.value.status = val)
        watch(itemCategoryForm, val => {
            name.value = val.name
            description.value = val.description
            status.value = val.status
        })

        return {
            showPanel,
            togglePanel,
            name,
            description,
            status,
            itemCategoryForm
        }
    }
}
